import React, { useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";

import { useDispatch, useSelector } from "react-redux";

import { useLocation } from "react-router-dom";
import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  asyncLoadingFunction,
  setFlagForm,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "../../../../redux/actions/helperActions";
import {
  setActiveBonus,
  setOpenBonusDetails,
  startFilterBonus,
  startToggleBonusAltenar,
  startUpdateBonus,
} from "../../../../redux/actions/bonusActions";
import {
  Enum_FieldType,
  IFilterField,
} from "../../../../interfaces/components";
import { ProvidersBonus, State } from "../../../../enums/enums";
import { FormCreateBonus } from "../../../components/managementBonus/FormCreateBonus";
import Filter from "../../../../components/Filter/Filter";

import MyCustomTable from "../../../../components/Custom/Table/MyCustomTable";
import { RowManagementBonus } from "../../../components/managementBonus/RowManagementBonus";
import { IBonus, IQueryFilterBonus } from "../../../../interfaces/bonus";

import { BonusDeliverDetails } from "./BonusDeliverDetails";
import { MyCustomDialog } from "../../../../components/Custom/MyCustomDialog";

import { BonusDetails } from "./BonusDetails";

import moment from "moment";
import { ManagementBonusDataTable } from "../../../../hooks/useDataTable/managementBonus";
import { ManagementBonusPdf } from "../../../../components/Pdf/managementBonus/ManagementBonusPdf";
import { filterBonus } from "../../../../services/bonus";

export const CreateBonusTab = () => {
  const { t } = useTranslation();
  const { flagFormCreate } = useSelector((state: AppState) => state.helper);

  const { rowsPerPage } = useSelector((state: AppState) => state.helper);
  const { user, token } = useSelector((state: AppState) => state.auth);

  const { bonus, openDetails } = useSelector((state: AppState) => state.bonus);
  const bonusData = bonus ? bonus.data : [];
  const dispatch = useDispatch();
  const [dataFilter, setDataFilter] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [openModalDetails, setOpenModalDetails] = useState(false);
  const { routes } = useSelector((state: AppState) => state.managementMenus);
  const [isFiltering, setIsFiltering] = useState(false);
  const { pathname } = useLocation();

  const modulePermissions = routes
    .find((route) => !!route.menuItem.find((module) => module.url === pathname))
    ?.menuItem.find((module) => module.url === pathname);

  useEffect(() => {
    dispatch(setFlagForm({ flagFormCreate: false }));
    const fetchBonus = async () => {
      if (user) {
        let params: any = {
          page: currentPage,
          perPage: rowsPerPage,
        };
        await dispatch(
          asyncLoadingFunction(() =>
            dispatch(
              startFilterBonus({
                ...params,
              })
            )
          )
        );
      }
    };
    fetchBonus();
    return () => {
      dispatch(setFlagForm({ flagFormCreate: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FilterFileds: IFilterField[] = [
    {
      label: t("managementBonds.bonusCode"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("managementBonds.bondName"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("menu.users.creator"),
      type: Enum_FieldType.TEXT,
    },
    {
      label: t("form.state"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: t("state.active"),
          value: State.ACTIVE,
        },
        {
          label: t("state.inactive"),
          value: State.INACTIVE,
        },
      ],
    },
    {
      label: t("managementBonds.provider"),
      type: Enum_FieldType.SELECT,
      options: [
        {
          label: "Pragmatic",
          value: 3,
        },
        {
          label: "Pariplay",
          value: 5,
        },
        {
          label: "Altenar",
          value: 1,
        },
      ],
    },
  ];

  useEffect(() => {
    if (bonus && bonus.data.length === 0) {
      dispatch(
        setMessageAlert(isFiltering ? "alerts.noData" : "alerts.noRecords")
      );
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bonus]);

  const handleShowForm = () => {
    dispatch(setFlagForm({ flagFormCreate: true }));
  };
  const handlePageChange = async (page: number, rows?: number) => {
    setCurrentPage(page);
    await dispatch(
      startFilterBonus({
        page: page,
        perPage: rows || rowsPerPage,
        ...dataFilter,
      })
    );
  };
  const handleFilter = async (values: any) => {
    setIsFiltering(true);
    const params: IQueryFilterBonus = {
      state: values[t("form.state")].toString(),
      code: values[t("managementBonds.bonusCode")].toString(),
      name: values[t("managementBonds.bondName")],
      createdUser: values[t("menu.users.creator")],
      provider: values[t("managementBonds.provider")],
    };
    await dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startFilterBonus({
            ...params,
            page: 1,
          })
        )
      )
    );
    setDataFilter({ ...params });
  };
  const onCleanFilter = async () => {
    dispatch(
      asyncLoadingFunction(() =>
        dispatch(
          startFilterBonus({
            page: 1,
          })
        )
      )
    );
    setDataFilter({});
    setIsFiltering(false);
  };
  const handleCloseDetails = () => {
    dispatch(setActiveBonus(null));
    dispatch(setOpenBonusDetails(false));
  };
  const handleEnableBonus = async (row: IBonus) => {
    dispatch(setActiveBonus(row));
    const updateObj = { ...row };

    if (row.provider.id === ProvidersBonus.ALTENAR) {
      await dispatch(
        startToggleBonusAltenar({
          ...updateObj,
        })
      );
    } else {
      //@ts-ignore
      delete updateObj.totalBonusDelivered;
      const finalState =
        row.state === State.ACTIVE ? State.INACTIVE : State.ACTIVE;
      await dispatch(
        startUpdateBonus({
          ...updateObj,
          state: finalState,
        })
      );
    }
    await dispatch(
      startFilterBonus({
        page: currentPage,
        perPage: rowsPerPage,
        ...dataFilter,
      })
    );
    dispatch(setActiveBonus(null));
  };
  const handleEditBonus = (row: IBonus) => {
    const fechaInicio = moment(row.startDate);
    const fechaFin = moment(row.endDate);
    const newRow = {
      ...row,
      startDate: fechaInicio.format("YYYY-MM-DD 00:00:00"),
      endDate: fechaFin.format("YYYY-MM-DD 23:59:59"),
      startTime: fechaInicio.format("HH:mm"),
      endTime: fechaFin.format("HH:mm"),
    };
    dispatch(setActiveBonus(newRow));
    dispatch(
      setFlagForm({
        flagFormCreate: true,
      })
    );
  };

  const handleSeeDetails = (row: IBonus) => {
    dispatch(setActiveBonus(row));
    setOpenModalDetails(true);
  };
  const handleCloseSeeDetails = () => {
    setOpenModalDetails(false);
    dispatch(setActiveBonus(null));
  };
  const getTotalBonus = async () => {
    if (user && token) {
      return await filterBonus(user.operator?.id, {}, token);
    }
  };

  return (
    <>
      {flagFormCreate ? (
        <FormCreateBonus />
      ) : (
        <Grid container spacing={2}>
          {modulePermissions &&
            modulePermissions.permissions &&
            modulePermissions.permissions.created === 1 && (
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  mb={2}
                >
                  <Box display="flex" alignItems="center">
                    <p
                      className="txt-primary-md"
                      style={{
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        alignSelf: "center",
                      }}
                    >
                      {t("managementBonds.createBonds").toLocaleUpperCase()}
                    </p>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={handleShowForm}
                      id="openFormBtn"
                      data-testid="openFormBtn"
                      style={{ border: "none", outline: "none" }}
                    >
                      <span className="btn-add">
                        <i
                          className="fa-solid fa-plus"
                          style={{ fontSize: 30 }}
                        ></i>
                      </span>
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            )}

          <Grid item xs={12}>
            <Filter
              fields={FilterFileds}
              handleFilter={handleFilter}
              onClean={onCleanFilter}
            />
          </Grid>
          <Grid item xs={12}>
            <MyCustomTable
              columns={[
                {
                  align: "center",
                  label: t("managementBonds.bonusCode"),
                },
                {
                  align: "center",
                  label: t("managementBonds.bondName"),
                },
                {
                  align: "center",
                  label: t("managementBonds.provider"),
                },
                {
                  align: "center",
                  label: t("filters.startDate"),
                },
                {
                  align: "center",
                  label: t("table.expirationDate"),
                },
                {
                  align: "center",
                  label: t("managementBonds.amountBonus"),
                },
                {
                  align: "center",
                  label: t("table.state"),
                },
                {
                  align: "center",
                  label: t("table.actions"),
                },
              ]}
              data={bonusData}
              lastPage={bonus?.last_page || 1}
              totalData={bonus?.total || 0}
              renderBody={(item) => (
                <RowManagementBonus
                  row={item}
                  actions={{
                    see: true,
                    edit: true,
                    seeMore: true,
                  }}
                  onSee={handleEnableBonus}
                  onEdit={handleEditBonus}
                  onSeeMore={handleSeeDetails}
                />
              )}
              onPageChange={handlePageChange}
              exportData
              getAllDataToExport={getTotalBonus}
              hookBuildTable={ManagementBonusDataTable}
              pdfComponent={ManagementBonusPdf}
              titleCSV={`${t("tabs.bonds")}-${new Date().toISOString()}`}
            />
          </Grid>

          <MyCustomDialog
            open={openDetails}
            content={<BonusDeliverDetails />}
            onClose={handleCloseDetails}
            title={t("managementBonds.infoDeliveredBonus")}
            icon={
              <i
                className="fa-solid fa-circle-info"
                style={{ color: "#02D0E7", marginRight: 9, fontSize: 20 }}
              ></i>
            }
          />
          <MyCustomDialog
            open={openModalDetails}
            content={<BonusDetails />}
            onClose={handleCloseSeeDetails}
            title={t("managementBonds.bonusDetail")}
            icon={
              <i
                className="fa-solid fa-circle-info"
                style={{ color: "#02D0E7", marginRight: 9, fontSize: 20 }}
              ></i>
            }
            width="xl"
          />
        </Grid>
      )}
    </>
  );
};