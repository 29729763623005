import { ICasinoQuery } from "../../interfaces/casino";
import {
  createCasinoCategory,
  deleteCasinoCategory,
  getCasinoActivity,
  getCasinoCategory,
  getGames,
  updateCasinoCategory,
  updateGame,
  updateOrderGame,
} from "../../services/casino";
import { AppState } from "../reducers/rootReducer";
import { Types } from "../types/types";
import {
  setFlagForm,
  setLoadingState,
  setMessageAlert,
  setOpenAlert,
  setTypeAlert,
} from "./helperActions";
import { arraySearch } from "./searchActions";

export const startGetCasinoActivity = (params: Partial<ICasinoQuery>) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token } = (getState() as AppState).auth;
      const { rowsPerPage } = (getState() as AppState).helper;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      if (params.page) {
        params.perPage = rowsPerPage;
      }

      let finalParams: any = {};
      Object.entries(params).forEach(([key, value], index) => {
        //@ts-ignore
        if (typeof value === "number" || value.length > 0) {
          //@ts-ignore
          finalParams[key] = value;
        }
      });
      const response = await getCasinoActivity(token, finalParams);

      if (response && !response.error) {
        return dispatch(arraySearch(response));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startGetCasinoCategories = (params?: any) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token } = (getState() as AppState).auth;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      const response = await getCasinoCategory(token, params);
      if (response) {
        dispatch(setCasinoCategory(response));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startUpdateCasinoCategories = (
  params: any,
  id: number,
  type: number
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token } = (getState() as AppState).auth;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      dispatch(setLoadingState({ loading: true }));
      params.state = 1;
      params.type = type;
      console.log("🚀 ~ return ~ params:", params);
      const response = await updateCasinoCategory(token, params, id);
      if (response) {
        const params = {
          type: type,
        };
        await dispatch(startGetCasinoCategories(params));
        await dispatch(setActiveCategory(null));
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("Categoría actualizada exitosamente"));
        dispatch(setTypeAlert("success"));
        dispatch(setFlagForm({ flagFormCreate: false }));
        dispatch(setLoadingState({ loading: false }));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
      dispatch(setLoadingState({ loading: false }));
    }
  };
};

export const startGetGames = (params?: any) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token } = (getState() as AppState).auth;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      const response = await getGames(token, params);
      if (response) {
        dispatch(setCasinoGames(response));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startCreateCasinoCategory = (params: any, type: number) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token } = (getState() as AppState).auth;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      dispatch(setLoadingState({ loading: true }));
      params.state = 1;
      params.type = type;
      const response = await createCasinoCategory(token, params);
      if (response) {
        const params = {
          type: type,
        };
        await dispatch(startGetCasinoCategories(params));
        await dispatch(setActiveCategory(null));
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("Categoría creada exitosamente"));
        dispatch(setTypeAlert("success"));
        dispatch(setFlagForm({ flagFormCreate: false }));
        dispatch(setLoadingState({ loading: false }));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
      dispatch(setLoadingState({ loading: true }));
    }
  };
};

export const startDeleteCasinoCategory = (id: number, type: number) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token } = (getState() as AppState).auth;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      const response = await deleteCasinoCategory(id, token);
      if (response) {
        const params = {
          type: type,
        };
        await dispatch(startGetCasinoCategories(params));
        await dispatch(setActiveCategory(null));
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("Categoría eliminada exitosamente"));
        dispatch(setTypeAlert("success"));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startUpdateGame = (params: any, id: number) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token } = (getState() as AppState).auth;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      const response = await updateGame(token, params, id);
      if (response) {
        await dispatch(startGetGames());
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("Juego actualizado exitosamente"));
        dispatch(setTypeAlert("success"));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const startUpdateOrderGame = (params: any) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { token } = (getState() as AppState).auth;
      if (!token) {
        throw new Error("INVALID ACTION");
      }
      const response = await updateOrderGame(token, params);
      if (response) {
        await dispatch(startGetGames());
        dispatch(setOpenAlert());
        dispatch(setMessageAlert("Juegos ordenados exitosamente"));
        dispatch(setTypeAlert("success"));
      }
    } catch (error) {
      dispatch(setMessageAlert("alerts.error"));
      dispatch(setTypeAlert("error"));
      dispatch(setOpenAlert());
    }
  };
};

export const setCasinoCategory = (casinoCategory: any) => ({
  type: Types.setCasinoCategory,
  payload: casinoCategory,
});

export const setCasinoGames = (casinoGames: any) => ({
  type: Types.setCasinoGames,
  payload: casinoGames,
});

export const setActiveCategory = (activeCategory: any) => ({
  type: Types.setActiveCategory,
  payload: activeCategory,
});
