import {
  ICreateExtenalUser,
  IExternalUsersData,
} from "../interfaces/commercialNetworks";
import {IPaginationProps} from "../interfaces/shared";

export interface IQueryParams extends IPaginationProps {
  operatorId: string;
  network: string;
  date?: string;
}

export interface IConciliationExcel {
  conciliation: string;
  conciliationDate: string;
  network: string;
  timezone: string | null;
}

export const getExternalUsers = (params: Partial<IQueryParams>) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_EXTERNAL_TRANSFER}users?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const createCommercialNetwork = (data: ICreateExtenalUser) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_EXTERNAL_TRANSFER}users`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const updateCommercialNetwork = (
  id: number,
  data: Partial<IExternalUsersData>
) => {
  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_HTTP_EXTERNAL_TRANSFER}users/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData)
    .catch((error) => console.warn(error));
};

export const getConciliations = (params: Partial<IQueryParams>) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_EXTERNAL_TRANSFER}conciliations?${queryParams}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const getConciliationById = (id: number) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${process.env.REACT_APP_API_HTTP_EXTERNAL_TRANSFER}conciliations/${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((responseData) => responseData);
};

export const downloadExcel = (params: IConciliationExcel) => {
  const requestOptions = {
    method: "GET",
  };
  const queryParams = params
    ? new URLSearchParams(params as any).toString()
    : "";

  return fetch(
    `${process.env.REACT_APP_API_HTTP_EXTERNAL_TRANSFER}conciliations/download?${queryParams}`,
    requestOptions
  )
    .then((response) => response)
    .catch((error) => console.warn(error));
};
